<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center my-1 py-1">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/nav_logo.png')"
              max-height="200px"
              max-width="200px"
              alt="logo"
              class="mx-0 px-0"
              contain
            ></v-img>
          </router-link>
        </v-card-title>
        <v-card-text
          v-if="registerLoading"
          class="fadeOut text-center d-all justify-center mx-auto my-auto"
        >
          <v-progress-circular
            :size="50"
            color="success"
            indeterminate
            class="d-all text-center mx-auto my-auto"
          ></v-progress-circular>
        </v-card-text>

        <!-- title -->
        <div v-if="!registerLoading && !user.isLoggedin">
          <v-card-text class="my-1 py-1">
            <p class="text-2xl font-weight-semibold text--success mb-2 text-center">
              Create your account
            </p>
            <div class="text-center">
              <v-badge
                color="warning"
                class="mb-3 mt-2 mr-0"
                overlap
                content="Beta"
              ></v-badge>
            </div>
          </v-card-text>
          <!-- login form -->
          <v-card-text>
            <v-snackbar
              v-model="snackbarShow"
              :color="snackbarType"
              :absolute="true"
              :top="true"
              :timeout="snackbarTimeout"
            >
              <v-layout
                align-center
                pr-4
              >
                <v-icon
                  class="pr-3"
                  dark
                  large
                >
                  {{ snackbarIcon }}
                </v-icon>
                <v-layout column>
                  <div>{{ snackbarText }}</div>
                </v-layout>
              </v-layout>
            </v-snackbar>

            <v-form
              id="register-form"
              @submit.prevent="Register"
            >
              <v-text-field
                v-model="user.displayName"
                outlined
                color="success"
                label="Name"
                hide-details
                class="mb-3"
                required
              ></v-text-field>
              <v-text-field
                v-model="user.email"
                outlined
                color="success"
                label="Email"
                type="email"
                name="email"
                placeholder="Email"
                required
                class="email-input email-extra mb-3"
                value
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="user.password"
                outlined
                color="success"
                label="Password"
                type="password"
                name="password"
                placeholder="Password"
                required
                class="password-input email-extra mb-3"
                value
                hide-details
              ></v-text-field>
              <v-btn
                type="submit"
                form="register-form"
                block
                :loading="registerLoading"
                color="success"
                class="mt-6"
              >
                Sign Up
              </v-btn>
              <p class="text-xs mt-3">
                By creating an account, you're agreeing to FantasyKombat's <a
                  target="_blank"
                  href="https://support.fantasykombat.com/en/article/terms-of-service-qhnr7c/"
                >Terms of
                  Service.</a> Read our <a
                  target="_blank"
                  href="https://support.fantasykombat.com/en/article/privacy-policy-hlxqnr/"
                >Privacy Policy.</a>
              </p>
            </v-form>
          </v-card-text>

          <!-- create new account  -->
          <v-card-text class="d-flex align-center justify-center flex-wrap mt-1">
            <span class="me-1">
              Already have an account?
            </span>
            <router-link :to="{ name:'login' }">
              Login
            </router-link>
          </v-card-text>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiAccountRemoveOutline,
  mdiAccountCheck,
} from '@mdi/js'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  props: {
    leagueCode: {
      type: String,
      default: null,
    },
  },
  setup() {
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      socialLink,
      registerLoading: false,
      snackbarText: '',
      snackbarType: 'success',
      snackbarTimeout: 5000,
      snackbarShow: false,
      snackbarIcon: '',

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data() {
    return {
      user: {},
    }
  },
  mounted() {
    this.$cookies.set('joincode', this.leagueCode)
    if (this.$store.state.user?.isLoggedin) {
      this.user = this.$store.state.user
      if (!this.user?.profile.onBoardered) {
        this.$router.push('/onboard')
      } else if (this.leagueCode) {
        this.$router.push('/leagues')
      } else {
        this.$router.push('/dashboard')
      }
    } else {
      this.user = {
        displayName: '',
        email: '',
        password: '',
        isLoggedin: false,
        photoURL: require('@/assets/images/avatars/default.png'),
      }
    }
  },
  methods: {
    Register() {
      this.registerLoading = true
      firebase.auth()
        .createUserWithEmailAndPassword(this.user.email, this.user.password)
        .then(response => {
          response.user.updateProfile({
            displayName: this.user.displayName,
          })
            .then(() => {
              this.showSnack = true
              this.snackbarType = 'success'
              this.snackbarIcon = mdiAccountCheck
              this.snackbarText = 'Account successfully created!'

              // clear password
              this.user.password = ''
              this.user.isLoggedin = true
              this.$store
                .dispatch('signup', this.user)
                .then(() => {
                  this.$store
                    .dispatch('login', this.user)
                    .then(() => {
                      this.snackbarShow = true
                      this.snackbarType = 'success'
                      this.snackbarText = 'Login Successful'
                      this.snackbarIcon = mdiAccountCheck
                      this.$router.push('/dashboard')
                    })
                    .catch(err => {
                      console.log(err)
                      this.registerLoading = false
                      this.snackbarShow = true
                      this.snackbarIcon = mdiAccountRemoveOutline
                      this.snackbarType = 'error'
                      this.snackbarText = err.message
                    })
                })
                .catch(err => {
                  console.log(err)
                  this.registerLoading = false
                  this.snackbarShow = true
                  this.snackbarType = 'error'
                  this.snackbarIcon = mdiAccountRemoveOutline
                  this.snackbarText = err.message
                })
            })
        })
        .catch(err => {
          console.log(err)
          this.registerLoading = false
          this.snackbarShow = true
          this.snackbarType = 'error'
          this.snackbarIcon = mdiAccountRemoveOutline
          this.snackbarText = err.message
        })
    }
    ,

  }
  ,
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.text-danger {
  color: #EF5350;
}
</style>
